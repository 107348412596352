<template>
  <v-row>
    <v-col cols="12" class="pt-0 px-0">
      <!-- digital certificate -->
      <v-toolbar v-if="$helpers.hasPermission(['view_genericcertificate'])" flat height="50" class="transparent px-3">
        <v-toolbar-title class="subtitle-2 fontBody--text font-weight-bold">Certificados</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center" justify="center" no-gutters>
            <v-btn v-if="$helpers.hasPermission(['add_genericcertificate'])" @click="modalCertificate=true" color="primary" :ripple="false"><v-icon left >mdi-upload</v-icon>Subir certificado</v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
      <v-row class="mt-5 px-1" v-if="loaderCertificate">
        <v-col cols="6" class="pa-0" v-for="item in 2" :key="item">
          <v-skeleton-loader class="mt-n3 rounded-md pa-2" transition="fade-transition" type="image" />
        </v-col>
      </v-row>
       <template v-else>
        <v-card v-if="!$helpers.hasPermission(['view_genericcertificate'])" class="my-5" flat>
          <PermissionDenied :isDetail="true" />
        </v-card>
        <template v-else>
          <v-col class="pb-0 pt-4 text-center" v-if="!certificatesList?.length">
            <v-empty-state type="certificate" customClass="mt-8" :isFree="true" title="Aún no tienes certificados" :height="169" />
          </v-col>
          <v-row v-else no-gutters>
            <v-col :cols="$vuetify.breakpoint.width > 1580 ? 4 : 6" class="py-0 px-2" v-for="(cert, index) in certificatesList" :key="index">
              <v-card ref="certificate" class="my-5" flat>
                <v-card-text class="px-5 py-4">
                  <v-list class="v-list-form-2 transparent pa-0">
                    <v-list-item class="pa-0">
                      <v-list-item-title>Nombre</v-list-item-title>
                      <v-list-item-subtitle class="body-1 secondary--text ml-n4">{{cert.name}} • {{$t('generals.RUN')}} {{cert.tax_id}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title>Validez
                        <v-tooltip color="bgSearch" v-if="cert?.date_end && dateIsBefore(cert?.date_end, today)" right max-width="280px" transition="scale-transition">
                          <template v-slot:activator="{on}">
                            <v-icon v-on="on" small color="red">mdi-information</v-icon>
                          </template>
                          <span class="d-block px-3 py-2">El certificado digital está vencido. Para resolver el problema compra un nuevo certificado y súbelo a {{ env === 'ax' ? 'Axteroid' : 'Factura Chile' }}.</span>
                        </v-tooltip>
                      </v-list-item-title>
                      <v-list-item-subtitle class="body-1 secondary--text ml-n4">desde el {{cert.date_start | date}} hasta el {{cert.date_end | date}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title>Emisor</v-list-item-title>
                      <v-list-item-subtitle class="body-1 secondary--text ml-n4">{{cert.issuer}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title>Serial</v-list-item-title>
                      <v-list-item-subtitle class="body-1 secondary--text ml-n4">{{cert.serial}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title>ID</v-list-item-title>
                      <v-list-item-subtitle class="body-1 secondary--text ml-n4">{{cert.digest}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title>Email</v-list-item-title>
                      <v-list-item-subtitle class="body-1 secondary--text ml-n4">{{cert.email}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title>Fecha de subida</v-list-item-title>
                      <v-list-item-subtitle class="body-1 secondary--text ml-n4">{{cert.created | dateTimeSecond}}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </template>
    <!-- end digital certificate -->

      <!-- folios -->
      <!-- <v-card ref="folios" class="my-5" flat>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Folios</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn class="ml-5" @click="dialogCreateFolio=true" outlined>Subir folio</v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-skeleton-loader class="mt-5" v-if="mainLoader" transition="fade-transition" type="table" />
          <template v-else>
            <v-expansion-panels v-model="expansionPanelCafs" v-if="cafsList.length" accordion tile flat>
              <v-expansion-panel v-for="(d, i) in documentTypes" :key="d.id">
                <v-expansion-panel-header>
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col>
                      <span class="d-block body-2 text--secondary font-weight-medium">
                        {{d.name}}
                        <v-tooltip color="bgSearch"  v-if="!cafExists(d.id, d.next_number)" right max-width="280px" transition="scale-transition">
                          <template v-slot:activator="{on}">
                            <v-icon v-on="on" small color="red">mdi-information</v-icon>
                          </template>
                          <span class="d-block px-3 py-2">No tienes folios disponibles para <span class="text--secondary font-weight-medium">{{d.name}}</span>. Solicita y sube un folio para resolver el problema.</span>
                        </v-tooltip>
                      </span>
                    </v-col>
                    <v-col v-if="expansionPanelCafs === i">
                      <v-tooltip color="bgSearch" max-width="280px" top transition="scale-transition">
                        <template v-slot:activator="{on}">
                          <v-icon class="mt-n1" v-on="on" small color="secondary">mdi-information</v-icon>
                        </template>
                        <span class="d-block px-3 py-2">Es el número que será asignado al siguente documento de tipo <span class="text--secondary font-weight-medium">{{d.name}}</span> que sea creado.</span>
                      </v-tooltip>
                      Núm. siguiente {{d.next_number}}
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mx-0">
                    <v-col class="px-0" cols="12">
                      <v-card class="overflow-hidden">
                        <v-simple-table class="text-primary" dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th>Números</th>
                                <th>Vigencia</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(c, i) in orderedCafs[d.id]">
                                <tr :key="i">
                                  <td>
                                    <span class="text--secondary font-weight-medium">{{c.from_number}}</span> al <span class="text--secondary font-weight-medium">{{c.to_number}}</span>
                                    <template v-if="d.next_number >= c.from_number && d.next_number <= c.to_number">
                                      <v-chip v-if="!cafIsLive(c.life_to)" class="ml-2" color="red lighten-5" text-color="red" small>Vencido</v-chip>
                                      <v-chip v-else class="ml-2" color="lightBlue" text-color="primary" small>En uso</v-chip>
                                    </template>
                                  </td>
                                  <td>desde <span class="text--secondary font-weight-medium">{{c.life_from | date}}</span> hasta <span class="text--secondary font-weight-medium">{{c.life_to | date}}</span></td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
                <v-divider />
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="mx-0" v-else>
              <v-col class="py-5 text-center">
                <v-icon class="d-block" color="secondary" size="70">mdi-numeric-0-box-multiple</v-icon>
                <span class="d-block subtitle-1 my-2">Aún no tienes folios</span>
                <v-btn @click="dialogCreateFolio=true" outlined>Subir folio</v-btn>
              </v-col>
            </v-row>
          </template>
          <v-row class="mx-1 py-3">
            <v-col class="py-0" cols="12">
              <v-list-item class="px-0">
                <v-list-item-title class="text--secondary subtitle-1 font-weight-medium">Ayuda</v-list-item-title>
              </v-list-item>
              <v-list class="transparent">
                <v-list-item class="px-0">
                  <v-list-item-title>
                    <v-btn :href="`${$DOCS_BASE}${guides[0]}`" target="_blank" text color="primary" :ripple="false">Cómo solicitar timbraje de folios en SII<v-icon right small>mdi-open-in-new</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-title>
                    <v-btn :href="`${$DOCS_BASE}${guides[1]}`" target="_blank" text color="primary" :ripple="false">Cómo subir folios<v-icon right small>mdi-open-in-new</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-title>
                    <v-btn :href="`${$DOCS_BASE}${guides[2]}`" target="_blank" text color="primary" :ripple="false">Cómo anular folios vencidos<v-icon right small>mdi-open-in-new</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->
      <!-- end folio -->

      <!-- tax settings -->
      <v-card ref="taxsettings" class="my-5" flat v-if="$store.state.auth.account.country === 'CL'">
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Otras opciones</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-skeleton-loader class="mt-n3" v-if="mainLoader" transition="fade-transition" type="list-item-three-line" />
          <v-list v-else class="v-list-form-extended transparent pa-0">
            <v-list-item class="pa-0">
              <v-list-item-title>Descripción de actividad</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8" class="pa-0">
                  <v-text-field v-model="$v.irsConnection.activity_description.$model" :error="$v.irsConnection.activity_description.$error" readonly outlined required single-line dense maxlength="64" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0 my-2">
              <v-list-item-title>Código de actividad {{$t('generals.SII')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8" class="pa-0">
                  <v-select v-model="$v.irsConnection.activity.$model" :error="$v.irsConnection.activity.$error" :items="activitiesList" readonly item-text="name" item-value="id" hide-details single-line dense height="30" outlined />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Fecha de resolución</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="3" class="pa-0">
                  <v-menu v-model="datePickerMenu" disabled :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="290">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="irsConnection.resolution_date" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                    </template>
                    <v-date-picker v-model="irsConnection.resolution_date" @input="datePickerMenu=false" readonly no-title :first-day-of-week="1" type="date" locale="es" max-width="270" color="primary">
                      <v-row class="mb-2 px-6" align="center" justify="end">
                        <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { documents.date=null; datePickerMenu=false }">Limpiar</v-btn>
                          <v-spacer />
                        <v-btn @click="() => { irsConnection.resolution_date=today; datePickerMenu=false }" color="primary">Hoy</v-btn>
                      </v-row>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0 my-2">
              <v-list-item-title>Número de resolución</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="3" class="pa-0">
                  <v-text-field v-model="irsConnection.resolution_number" dense readonly hide-details outlined single-line />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Crear cedible en facturas</v-list-item-title>
              <v-list-item-subtitle>
                <v-switch class="mt-n4" v-model="irsConnection.document_assignable_copy" readonly dense style="position:absolute" :ripple="false" />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-8 py-2">
          <v-spacer />
          <v-btn class="px-4" @click="resetForm" outlined>Cancelar</v-btn>
          <v-btn class="px-4" @click="instance.id ? updateIrsConnection() : createIrsConnection()" :loading="$store.state.irs.createLoader" color="primary">Guardar cambios</v-btn>
        </v-card-actions>
      </v-card>
      <!-- end tax settings -->
    </v-col>

    <!-- dialog create folio -->
    <v-dialog v-model="dialogCreateFolio" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Subir folios</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogCreateFolio=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-col cols="10" class="mx-auto">
            <v-list class="transparent">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-file-input v-model="newCaf.file" ref="newCaf" type="file" :error="$v.newCaf.file.$error" accept=".xml" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                  </v-list-item-title>
                  <v-list-item-subtitle class="pt-3">Selecciona o arrastra un archivo de folios obtenidos desde sii.cl</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="dialogCreateFolio=false" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="createCaf" :loading="$store.state.irs.createLoader" color="primary">Subir folio</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create folio -->

    <!-- dialog certificate -->
    <v-dialog v-model="modalCertificate" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Nuevo certificado digital</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="clear" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-list class="v-list-form transparent pa-0">
            <v-list-item class="pa-0">
              <v-list-item-title>
                Archivo del certificado
                <v-tooltip color="bgSearch" right transition="scale-transition" max-width="200px">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                  </template>
                  <span class="d-block px-3 py-2">Asegúrate que el certificado digital tenga formato/extensión .p12</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-file-input v-model="newCertificate.file" ref="newCertificate.file" :error="$v.newCertificate.file.$error" type="file" accept="application/x-pkcs12" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips dense />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>
                Contraseña
                <v-tooltip color="bgSearch" right transition="scale-transition" max-width="200px">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                  </template>
                  <span class="d-block px-3 py-2">La contraseña que ingresaste al comprar el certificado digital</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-text-field v-model="$v.newCertificate.password.$model" :error="$v.newCertificate.password.$error" type="password" outlined required single-line dense hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="clear" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="createCertificate" :loading="$store.state.irs.createLoader" color="primary">Subir certificado</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog certificate -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import VEmptyState from '@/components/commons/VEmptyState'
import irsList from '@/collections/irs'
import documentTypesList from '@/collections/documentTypes'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

const currentIrs = irsList.find(b => b.id === 'sii')
const documentTypes = documentTypesList.filter(d => d.country === 'CL')

export default {
  components: {
    VEmptyState, PermissionDenied
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    datePickerMenu: false,
    backupIrsConnection: {},
    irs: currentIrs,
    expansionPanelCafs: null,
    guides: [
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#solicitud-de-timbraje-de-folios',
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#folios',
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#anulacion-de-folios-vencidos'
    ],
    menu: false,
    file: null,
    documentTypes: documentTypes,
    mainLoader: false,
    modalCertificate: false,
    loaderCertificate: false,
    newCaf: {
      file: null
    },
    newCertificate: {
      file: null,
      password: ''
    },
    irsConnection: {
      test_mode: false,
      activity: null,
      activity_description: '',
      resolution_date: '',
      resolution_number: null,
      document_assignable_copy: false
    },
    editableSequence: {
      next_number: null
    },
    dialogCreateFolio: false,
    sequenceDocuments: ['CL33', 'CL34', 'CL39', 'CL41', 'CL46', 'CL52', 'CL56', 'CL61', 'CL110', 'CL111', 'CL112'],
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: {
    ...mapState({
      activitiesList: state => state.irs.activitiesList,
      instance: state => state.irs.connectionsList[0] || {},
      cafsList: state => state.irs.cafsList,
      certificatesList: state => state.irs.certsList,
      sequencesList: state => state.documents.sequencesList
    }),
    orderedCafs () {
      const types = this.documentTypes.map(s => s.id)
      const orderedCafs = {}

      types.forEach(d => {
        orderedCafs[d] = this.cafsList.filter(c => c.document_type === d)
      })

      return orderedCafs
    }
  },
  created () {
    // if (this.$helpers.hasPermission(['view_genericcertificate'])) {
      this.getList()
    // }
    // this.mainLoader = true
    // Promise.all([
    //   this.$store.dispatch('irs/LIST', {
    //     resource: 'irs/connections'
    //   }),
    //   this.$store.dispatch('irs/LIST', {
    //     resource: 'irs/sii/activities',
    //     query: {
    //       page_size: 1000
    //     }
    //   })
    // ])
    // .then(() => {
    //   if (this.instance.id) {
    //     Object.keys(this.irsConnection).forEach(k => {
    //       this.irsConnection[k] = this.instance.extra_data[k]
    //     })
    //     this.backupIrsConnection = {...this.irsConnection}
    //   }
    // })
    // if (this.$helpers.hasPermission(['view_genericcertificate'])) {
    //   this.$store.dispatch('irs/LIST', {
    //     resource: `irs/${this.$t('generals.sii')}/cafs`
    //   })
    // }
    // this.mainLoader = false
  },
  mounted () {
    setTimeout(() => {
      if (this.$route.query.ref && this.$refs[this.$route.query.ref]) {
        this.$vuetify.goTo(this.$refs[this.$route.query.ref].$el, { offset: 20 })
      }
    }, 1500)
  },
  methods: {
    getList () {
      if (this.$helpers.hasPermission(['view_genericcertificate'])) {
        this.loaderCertificate = true
        this.$store.dispatch('irs/LIST', {
          resource: `certs`
        })
        .finally(() => {
          this.loaderCertificate = false
        })
      }
    },
    resetForm () {
      this.irsConnection = {...this.backupIrsConnection}
    },
    cafExists (document, nextNumber) {
      return this.orderedCafs[document].find(c => nextNumber >= c.from_number && nextNumber <= c.to_number && this.cafIsLive(c.life_to))
    },
    cafIsLive (endDate) {
      return moment(endDate).isSameOrAfter(this.today)
    },
    clear () {
      this.modalCertificate = false
      this.newCertificate = {
        file: null,
        password: ''
      }
      this.$v.newCertificate.$reset()
    },
    createCertificate () {
      this.$v.newCertificate.$touch()
      if (this.$v.newCertificate.$invalid) {
        return false
      }

      const payload = new FormData()
      payload.append('certificate', this.newCertificate.file, this.newCertificate.file.name)
      payload.append('password', this.newCertificate.password)

      this.$store.dispatch('irs/CREATE', {
        resource: `certs`,
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('El certificado digital ha sido creado con éxito')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
       this.clear()
      })
    },
    createCaf () {
      this.$v.newCaf.$touch()
      if (this.$v.newCaf.$invalid) {
        return false
      }

      const payload = new FormData()
      payload.append('file', this.newCaf.file, this.newCaf.file.name)

      this.$store.dispatch('irs/CREATE', {
        resource: `irs/${this.$t('generals.sii')}/cafs`,
        payload: payload,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(() => {
        this.$store.dispatch('documents/LIST', {
          resource: 'sequences',
          query: {
            document_type__id__in: this.sequenceDocuments.join(','),
            expand: 'document',
            page_size: 100
          }
        })
        .then(() => {
          this.dialogCreateFolio = false
          this.newCaf.file = null
        })
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
    },
    createIrsConnection () {
      this.$v.irsConnection.$touch()

      if (this.$v.irsConnection.$invalid) {
        return false
      }

      const payload = {
        api_key: 'api_key',
        irs: 'sii',
        extra_data: this.irsConnection
      }

      this.$store.dispatch('irs/CREATE', {
        resource: 'irs/connections',
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('La información fiscal de la organización se ha actualizado')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
    },
    updateIrsConnection () {
      this.$v.irsConnection.$touch()

      if (this.$v.irsConnection.$invalid) {
        return false
      }

      const payload = {
        api_key: 'api_key',
        irs: 'sii',
        extra_data: this.irsConnection
      }

      this.$store.dispatch('irs/UPDATE', {
        resource: 'irs/connections',
        id: this.instance.id,
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('La información fiscal de la organización se ha actualizado')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
    }
  },
  validations: {
    newCertificate: {
      file: {
        required
      },
      password: {
        required
      }
    },
    newCaf: {
      file: {
        required
      }
    },
    irsConnection: {
      activity: {
        required
      },
      activity_description: {
        required,
        maxLength: maxLength(56)
      }
    }
  }
}
</script>